import * as React from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  redirect,
} from "react-router-dom";
import NavigationBar from "./components/navigation";
import LandingPage from "./pages/home";
import Profile from "./pages/profile";
import FaqPage from "./pages/faq";
import EventDetails from "./pages/eventDetails";
import Event from "./pages/event";
import Feedback from "./components/event/feedback";
import Footer from "./components/footer";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { fetchToken } from "./features/authenticate/authSlice";
// import { Config } from "./authConfig";
// import { decode as base64_decode, encode as base64_encode } from "base-64";
import { userProfile } from "./features/profile/userProfileSlice";
import { fetchProfileDetails } from "./features/profile/profileDetailsSlice";
import { checkInForEvent } from "./features/eventDetails/eventCheckInSlice";
import Dashboard from "./pages/dashboard";
import CheckInAttendance from "./pages/attendance";

function Pages() {
  return (
    <HashRouter>
      {window.location.pathname !== "/attendance" && <NavigationBar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/spocs" element={<LandingPage />} />
        <Route path="/volunteering-events" element={<Event />} />
        <Route path="/my-profile" element={<Profile />} />
        <Route path="/faq-and-guidelines" element={<FaqPage />} />
        <Route path="/event-details/eventId/:id" element={<EventDetails />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/attendance" element={<CheckInAttendance />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {window.location.pathname !== "/attendance" && <Footer />}
    </HashRouter>
  );
}

export default function App() {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(
    (state) => state.authenticate.authResponse
  );
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState(
    new URLSearchParams(window.location.search)
  );

  React.useEffect(() => {
    if (!window.location.hash) {
      window.location.replace(window.location.href + "#/");
    }
  }, []);

  React.useEffect(() => {
    const loginTime = localStorage.getItem("loginTime"); 
    const sessionTime = (Number(loginTime) - Date.now())/1000/60;
    const token = sessionTime <= 30 ? localStorage.getItem("access_token") : null;
    if (!loggedIn && searchParams.size < 1 && token == null) {
      localStorage.setItem("redirect_after_login", window.location.href);
      window.location.href = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_API_URL}`;
    } else if (
      !loggedIn &&
      searchParams &&
      searchParams.size == 1 &&
      searchParams.get("state") != null &&
      token == null
    ) {
      localStorage.setItem("redirect_after_login", window.location.href);
      window.location.href = `${
        process.env.REACT_APP_AUTH_URL
      }?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=${
        process.env.REACT_APP_AUTH_SCOPE
      }&state=${searchParams.get("state")}&redirect_uri=${
        process.env.REACT_APP_API_URL
      }`;
    }
    if (localStorage.getItem("shortId")) {
      // on Page Refresg Get User Data
      console.log('profile details fetched once logged in');     
      getProfileApiCall()
    }
  }, []);

  const getProfileApiCall = () =>{
    dispatch(userProfile()).then((res) => {
      const profileData = res.payload;
      localStorage.setItem("userId", profileData.id); 
      console.log('profile details fetched');
      if(res.meta.requestStatus === 'fulfilled'){
        console.log('profile details fetched fulfilled');
        const redirect_url = localStorage.getItem("redirect_after_login");
        if (redirect_url) {
          window.location.href = redirect_url;
        }
      }
    }).catch((error) => {
      console.error("Failed to fetch user profile:", error);
    });
  }

  React.useEffect(() => {
    const code = searchParams.get("code");
    const loginTime = localStorage.getItem("loginTime"); 
    const sessionTime = (Number(loginTime) - Date.now())/1000/60;
    const token = sessionTime <= 30 ? localStorage.getItem("access_token") : null;
    const state = searchParams.get("state");
    if (code || state) {
      searchParams.delete("code");
      searchParams.delete("state");
      setSearchParams(searchParams);
      const newSearch = searchParams.toString();
      const newUrl = `${window.location.pathname}${newSearch}${window.location.hash}`;
      window.history.replaceState({}, "", newUrl);
    }

    try {
      if (code) {
        localStorage.removeItem("shortId");
        localStorage.removeItem("role");
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem("expires_in");
        localStorage.removeItem("loginTime");
        dispatch(fetchToken(code)).then((res) => {
          setLoggedIn(true);
          const tokenObj = res.payload;
          localStorage.setItem("shortId", tokenObj.shortId);
          localStorage.setItem("role", tokenObj.role);
          localStorage.setItem("access_token", tokenObj.access_token);
          localStorage.setItem("refresh_token", tokenObj.refresh_token);
          localStorage.setItem("expires_in", tokenObj.expires_in);
          localStorage.setItem("loginTime", Date.now().toString());
          dispatch(fetchProfileDetails());
          console.log('profile details fetched after sso');           
          getProfileApiCall()
        });
      } else if (token != null) {
        setLoggedIn(true);
      }
      // dispatch(userProfile()).then((res) => {
      //   const profileData = res.payload;
      //   localStorage.setItem("userId", profileData.id); 
      //   console.log("Failed to fetch user profile: try",);
      //   const redirect_url = localStorage.getItem("redirect_after_login");
      //   if (redirect_url) {
      //     window.location.href = redirect_url;
      //   }
      // }).catch((error) => {
      //   console.error("Failed to fetch user profile: catch", error);
      // });
  
      if (state) {
        if (state.indexOf("-") > -1) {
          let parseData = state.split("-");
          if (parseData.length > 0 && parseData.length == 2) {
            let code = state.split("-")[0];
            let eventId = state.split("-")[1];
            localStorage.setItem("eventId", eventId);

            setTimeout(() => {
              // use Code for CheckIn
              dispatch(checkInForEvent(code));
            }, 6000);

            setTimeout(() => {
              window.location.href = "/attendance";
            }, 1000);
          }
        } else {
          let eventId = state;
          localStorage.setItem("eventId", eventId);

          setTimeout(() => {
            window.location.href = `/event-details/${eventId}`;
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return <>{loggedIn && <Pages />}</>;
}